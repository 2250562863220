<template>
    <layout :classes="classes" :routeName="routeName">
        <div class="row">
            <div class="content">
                <div class="form-container">
                    <div class="title-container">
                        <h3 class="heading small">{{$t('login.change_temporary_password')}}</h3>
                    </div>
                    <div class="form login">
                        <div class="form-content">
                            <div class="input-container">
                                <label id="password-label" :class="error('password')">{{$t('login.form.new_password')}}</label>
                                
                                <div class="password-container">
                                    <input id="password-input" :class="error('password')" v-model="data.password" :type="pwd_field_type" :placeholder="$t('login.form.password.placeholder')" @keyup.enter="change_temporary_password()">
                                    <span class="show-password cta cta-form dark" @click="change_password_field_type()"><font-awesome-icon :icon="['fa', pwd_field_type === 'password' ? 'eye' : 'eye-slash']" /></span>
                                </div>
                                
                                <FormError :data="{...validation.password, name: 'password'}"/>                                
                            </div>

                            <button id="submit-button" class="cta cta-primary submit" :class="{ loading: loading }" @click="change_temporary_password()">{{$t('login.form.change')}}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="lb-bg"></div>

            <img v-if="$screen.width <= 768" class="bottom-bg mobile" src="../../assets/images/backgrounds/bg_auth_mobile.svg" alt="abstract background">
            <img v-else class="bottom-bg desktop" src="../../assets/images/backgrounds/bg_auth_desktop.svg" alt="abstract background">
        </div>
    </layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Layout from '../../components/Layout.vue';
import FormError from '../../components/Utils/FormError.vue';

export default {
    name: "ChangeTemporaryPassword",

    components: {
        Layout,
        FormError
    },

    data() {
        return {
            routeName: 'change-temporary-password',

            classes: {
                login: true
            },

            data: {
                password: ''
            },

            validation: {
                password: {
                    error: false,
                    key: 'errors.validation.passwordInvalid'
                }
            },

            formValidated: false,
            loading: false,

            pwd_field_type: 'password',
        }
    },

    computed: {
        ...mapGetters([
            'reinitialize_temp_password'
        ])
    },

    methods: {
        ...mapActions([
            'setToken'
        ]),

        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;
            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false

                if(!self.data[val] )
                    self.validation[val].error = true;
            });

            if(self.data.password.length < 10 ) {
                self.validation.password.error = true;
            }

            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error) {
                    self.formValidated = false
                }
            });
        },

        change_temporary_password: function() {
            const self = this;

            self.validate_mandatory_fields();
            if(self.formValidated) {
                self.loading = true;

                const data = {
                    password: self.data.password
                };

                self.$axios
                    .post('/password/change', data)
                    .then(response => {
                        self.loading = false;

                        if(response.status == 200) {
                            if(response.data) {
                                self.setToken(response.data);

                                if(self.$route.query && self.$route.query.redirect) {
                                    self.$utils._navigate_to_path(self.$route.query.redirect)
                                }else {
                                    self.$utils._navigate_to_name('dashboard')
                                }
                            }
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        change_password_field_type: function() {
            const self = this;
            self.pwd_field_type = self.pwd_field_type === 'password' ? 'text' : 'password'
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    },
    
    mounted() {
        const self = this;
        if(!self.reinitialize_temp_password) { self.$utils._navigate_to_name('dashboard') }
    }
};
</script>